import React, { useState } from "react";
import {
  CommonContainer,
  MediaQuery,
  mobileQuery,
} from "../../../components/Grid";
import LoadInView from "../../../components/LoadInView";
import {
  SectionHistoryStyle,
  HistoryWrapper,
  HistoryFigure,
  SectionTitle,
  HistoryGrid,
  HistoryCard,
  HistoryRow,
  Historybutton,
} from "../styled";
import { Parallax } from "react-scroll-parallax";

const SectionHistory = () => {
  const isMobile = MediaQuery(mobileQuery);
  const [more_history, set_more_history] = useState(0);
  const MoreButton = (e) => {
    if (!more_history) {
      set_more_history(1);
    }
  };
  return (
    <SectionHistoryStyle>
      <CommonContainer type="wide">
        <HistoryWrapper>
          <CommonContainer>
            {!isMobile ? (
              <>
                <HistoryFigure>
                  <Parallax
                    y={[30, -30]}
                    x={[-30, 0]}
                    tagOuter="figure"
                  ></Parallax>
                </HistoryFigure>

                <HistoryFigure>
                  <Parallax
                    y={[20, -20]}
                    x={[-20, 20]}
                    tagOuter="figure"
                  ></Parallax>
                </HistoryFigure>
              </>
            ) : (
              " "
            )}
            <SectionTitle>
              <small>연혁</small>
              우리가 걸어온 길
            </SectionTitle>
            <HistoryGrid>
              <HistoryRow>
                <LoadInView>
                  <HistoryCard>
                    <strong>2025. 01</strong>
                    <p>박찬용 대표이사 취임</p>
                  </HistoryCard>
                </LoadInView>
              </HistoryRow>
              <HistoryRow type="right">
                <LoadInView>
                  <HistoryCard>
                    <strong>2024. 03</strong>
                    <p>KB손해보험 KB라이프생명 차세대시스템 구축</p>
                  </HistoryCard>
                </LoadInView>
              </HistoryRow>
              <HistoryRow>
                <LoadInView>
                  <HistoryCard>
                    <strong>2023. 12</strong>
                    <p>대한민국 독서경영우수직장인증 및 가족친화 기업 인증</p>
                  </HistoryCard>
                </LoadInView>
              </HistoryRow>
              {/* <HistoryRow>
                <LoadInView>
                  <HistoryCard>
                    <strong>2023. 01</strong>
                    <p>신임 김명원 대표이사 취임</p>
                  </HistoryCard>
                </LoadInView>
              </HistoryRow> */}
              <HistoryRow type="right">
                <LoadInView>
                  <HistoryCard>
                    <strong>2021. 02</strong>
                    <p>인도네시아 현지법인 설립</p>
                  </HistoryCard>
                </LoadInView>
              </HistoryRow>
              {/* <HistoryRow>
                                    <LoadInView>
                                    <HistoryCard>
                                            <strong>2019.05</strong>
                                            <p>신임 최재을 대표이사 취임</p>
                                    </HistoryCard>
                                </LoadInView>
                            </HistoryRow> */}
              <HistoryRow type="right">
                <LoadInView>
                  <HistoryCard>
                    <strong>2018. 11</strong>
                    <p>KB국민은행 차세대 콜센터시스템 재구축 수주</p>
                  </HistoryCard>
                </LoadInView>
              </HistoryRow>
              <HistoryRow type="right">
                <LoadInView>
                  <HistoryCard>
                    <strong>2018. 10</strong>
                    <p>KB국민카드 차세대 디지털채널 고도화 및 지원공정 구축</p>
                  </HistoryCard>
                </LoadInView>
              </HistoryRow>
              <HistoryRow type="right">
                <LoadInView>
                  <HistoryCard>
                    <strong>2018. 07</strong>
                    <p>디지털R&D랩 설립</p>
                  </HistoryCard>
                </LoadInView>
              </HistoryRow>
              <HistoryRow>
                <LoadInView>
                  <HistoryCard>
                    <strong>2017. 06</strong>
                    <p>KB캐피탈 차세대시스템 구축</p>
                  </HistoryCard>
                </LoadInView>
              </HistoryRow>

              {more_history ? (
                <>
                  <HistoryRow type="right">
                    <LoadInView>
                      <HistoryCard>
                        <strong>2013. 10</strong>
                        <p>
                          아시안뱅커 선정 HR, Best Lending Platform (총 2개
                          부문) 베스트 프로젝트 상 수상
                        </p>
                      </HistoryCard>
                    </LoadInView>
                  </HistoryRow>
                  <HistoryRow>
                    <LoadInView>
                      <HistoryCard>
                        <strong>2012. 02</strong>
                        <p>스마트금융연구소 설립</p>
                      </HistoryCard>
                    </LoadInView>
                  </HistoryRow>
                  <HistoryRow type="right">
                    <LoadInView>
                      <HistoryCard>
                        <strong>2011. 06</strong>
                        <p>본사이전 (도화동 경찰공제회 빌딩)</p>
                      </HistoryCard>
                    </LoadInView>
                  </HistoryRow>

                  <HistoryRow>
                    <LoadInView>
                      <HistoryCard>
                        <strong>2010. 04</strong>
                        <p>
                          카자흐스탄 BCC은행 EDW 및 전행보고서시스템 구축 계약
                          체결
                        </p>
                      </HistoryCard>
                    </LoadInView>
                  </HistoryRow>
                  <HistoryRow>
                    <LoadInView>
                      <HistoryCard>
                        <strong>2010. 04</strong>
                        <p>해외(카자흐스탄 알마티)지점 설립</p>
                      </HistoryCard>
                    </LoadInView>
                  </HistoryRow>
                  <HistoryRow type="right">
                    <LoadInView>
                      <HistoryCard>
                        <strong>2009. 05</strong>
                        <p>KB국민은행 차세대 경영정보시스템 구축</p>
                      </HistoryCard>
                    </LoadInView>
                  </HistoryRow>
                  <HistoryRow type="right">
                    <LoadInView>
                      <HistoryCard>
                        <strong>2009. 03</strong>
                        <p>
                          Global IT기업들과의 전략적 사업협력을 위한 파트너쉽
                          MOU 체결
                        </p>
                      </HistoryCard>
                    </LoadInView>
                  </HistoryRow>
                  <HistoryRow>
                    <LoadInView>
                      <HistoryCard>
                        <strong>2008. 12</strong>
                        <p>KB국민은행 차세대 인터넷뱅킹시스템 구축</p>
                      </HistoryCard>
                    </LoadInView>
                  </HistoryRow>
                  <HistoryRow>
                    <LoadInView>
                      <HistoryCard>
                        <strong>2008. 09</strong>
                        <p>KB 금융지주 계열사 출범</p>
                      </HistoryCard>
                    </LoadInView>
                  </HistoryRow>
                  <HistoryRow type="right">
                    <LoadInView>
                      <HistoryCard>
                        <strong>2007. 06</strong>
                        <p>
                          베트남 농업은행 대외계 및 외화자금결제시스템 구축
                          프로젝트 참여
                        </p>
                      </HistoryCard>
                    </LoadInView>
                  </HistoryRow>
                  <HistoryRow type="right">
                    <LoadInView>
                      <HistoryCard>
                        <strong>2007. 05</strong>
                        <p>KDS New Vision 및 경영 이념 선포</p>
                      </HistoryCard>
                    </LoadInView>
                  </HistoryRow>
                  <HistoryRow>
                    <LoadInView>
                      <HistoryCard>
                        <strong>2006. 05</strong>
                        <p>공정거래 하도급 우수업체 수상(공정거래위원회)</p>
                      </HistoryCard>
                    </LoadInView>
                  </HistoryRow>
                  <HistoryRow>
                    <LoadInView>
                      <HistoryCard>
                        <strong>2006. 02</strong>
                        <p>
                          ‘대한민국 SW사업자 대상’ 산업SW부문
                          수상(한국소프트웨어산업협회)
                        </p>
                      </HistoryCard>
                    </LoadInView>
                  </HistoryRow>
                  <HistoryRow type="right">
                    <LoadInView>
                      <HistoryCard>
                        <strong>2005. 09</strong>
                        <p>본사이전(여의도 증권거래소)</p>
                      </HistoryCard>
                    </LoadInView>
                  </HistoryRow>
                  <HistoryRow type="right">
                    <LoadInView>
                      <HistoryCard>
                        <strong>2005. 04</strong>
                        <p>금융IT연구소 설립</p>
                      </HistoryCard>
                    </LoadInView>
                  </HistoryRow>
                  <HistoryRow>
                    <LoadInView>
                      <HistoryCard>
                        <strong>2004. 06</strong>
                        <p>인도네시아 BII은행 MIS프로젝트 참여</p>
                      </HistoryCard>
                    </LoadInView>
                  </HistoryRow>
                  <HistoryRow>
                    <LoadInView>
                      <HistoryCard>
                        <strong>2004. 04</strong>
                        <p>KB데이타시스템으로 사명 변경</p>
                      </HistoryCard>
                    </LoadInView>
                  </HistoryRow>
                  <HistoryRow type="right">
                    <LoadInView>
                      <HistoryCard>
                        <strong>2001. 12</strong>
                        <p>윤리강령 선포</p>
                      </HistoryCard>
                    </LoadInView>
                  </HistoryRow>
                  <HistoryRow>
                    <LoadInView>
                      <HistoryCard>
                        <strong>1996. 10</strong>
                        <p>소프트웨어사업자 신고(한국소프트웨어산업협회)</p>
                      </HistoryCard>
                    </LoadInView>
                  </HistoryRow>
                  <HistoryRow type="right">
                    <LoadInView>
                      <HistoryCard>
                        <strong>1995. 11</strong>
                        <p>자본금 증자(총 납입자본금 80억원)</p>
                      </HistoryCard>
                    </LoadInView>
                  </HistoryRow>
                  <HistoryRow type="right">
                    <LoadInView>
                      <HistoryCard>
                        <strong>1995. 10</strong>
                        <p>ALM적격업체 선정(상호저축은행중앙회)</p>
                      </HistoryCard>
                    </LoadInView>
                  </HistoryRow>
                  <HistoryRow>
                    <LoadInView>
                      <HistoryCard>
                        <strong>1993. 09</strong>
                        <p>시스템통합(SI) 사업자 등록</p>
                      </HistoryCard>
                    </LoadInView>
                  </HistoryRow>
                  <HistoryRow>
                    <LoadInView>
                      <HistoryCard>
                        <strong>1993. 08</strong>
                        <p>연구개발 전담부서 등록(과학기술부)</p>
                      </HistoryCard>
                    </LoadInView>
                  </HistoryRow>
                  <HistoryRow type="right">
                    <LoadInView>
                      <HistoryCard>
                        <strong>1991. 09</strong>
                        <p>㈜국민데이타시스템 설립</p>
                      </HistoryCard>
                    </LoadInView>
                  </HistoryRow>
                </>
              ) : (
                ""
              )}
            </HistoryGrid>
            {!more_history ? (
              <Historybutton>
                <button onClick={MoreButton}>더보기</button>
              </Historybutton>
            ) : (
              ""
            )}
          </CommonContainer>
        </HistoryWrapper>
      </CommonContainer>
    </SectionHistoryStyle>
  );
};

export default SectionHistory;
