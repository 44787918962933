import React from "react";
import CustomPopup3 from "../../../components/Popup/components/CustomPopup3";
import styled from "styled-components";

const VisionVideoPopupStyle = styled.div``;
const VisionVideoPopup5 = (props) => {
  return (
    <VisionVideoPopupStyle>
      <CustomPopup3
        type="video"
        youtube_id="umsMntqqBIg"
        show={props.show}
        onClose={props.onClose}
      ></CustomPopup3>
    </VisionVideoPopupStyle>
  );
};

export default VisionVideoPopup5;
