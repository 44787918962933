import React, { useRef, useState } from "react";
import Popup from "../../../components/Popup";
import Badge from "../../../components/Badge";
import SectionOperationCard from "./SectionOperationCard";
import { Row, Col, mobileQuery, MediaQuery } from "../../../components/Grid";
import YouTube from "react-youtube";
import CustomPopup7 from "../../../components/Popup/components/CustomPopup7";
import {
  MainContent,
  MainContentBadge,
  MainVideoWrapper,
  CardContainer,
} from "../styled";
import { motion } from "framer-motion";

const SectionOperation = (props) => {
  const video_el = useRef(null);
  const isMobile = MediaQuery(mobileQuery);
  const [VideoPopup_isshow, show_VideoPopup] = useState(0);
  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 1,
      mute: 1,
      loop: 1,
      playlist: "DZ-t8c2RvMM",
      controls: 0,
    },
  };
  const showVideoPopup = (e) => {
    e.preventDefault();
    if (VideoPopup_isshow) {
      show_VideoPopup(0);
    } else {
      show_VideoPopup(1);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 140 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1, ease: "easeOut", staggerChildren: 0.5 }}
    >
      <MainContent>
        <Row>
          <Col flex="2">
            <h4>시스템 운영</h4>
          </Col>
          <Col flex="8">
            <p>
              KB데이타시스템은 KB다운 마인드로 “고객중심”, “전문성”, “혁신주도”,
              “신뢰정직”, “동반성장”하는 IT서비스를 제공하고 있습니다.
            </p>
            <MainContentBadge>
              <Badge type="second" size="medium">
                #클라우드 시스템
              </Badge>
              <Badge type="second" size="medium">
                #Kronos
              </Badge>
            </MainContentBadge>
            <MainVideoWrapper bg="Visionitem4.png" onClick={showVideoPopup}>
              {!isMobile ? (
                // <YouTube
                //     videoId={"DZ-t8c2RvMM"}
                //     opts={opts}
                // />
                <video autoPlay={true} muted loop ref={video_el}>
                  <source
                    src="https://wp.daitso.kbds.co.kr/wp-content/uploads/2025/01/사업영역별-비전_시스템운영Upload.mp4"
                    type="video/mp4"
                  />
                </video>
              ) : (
                <div></div>
              )}
            </MainVideoWrapper>
            <CardContainer>
              <SectionOperationCard show_popup={props.show_popup} />
            </CardContainer>
          </Col>
        </Row>
        <CustomPopup7 show={VideoPopup_isshow} onClose={showVideoPopup} />
        {/* <Popup
          type="video"
          youtube_id="DZ-t8c2RvMM"
          show={VideoPopup_isshow}
          onClose={showVideoPopup}
        /> */}
        {/* <Popup type="video" src="https://wp.daitso.kbds.co.kr/wp-content/uploads/2025/01/사업영역별-비전_시스템운영Upload.mp4" show={VideoPopup_isshow} onClose={showVideoPopup}/> */}
      </MainContent>
    </motion.div>
  );
};

export default SectionOperation;
