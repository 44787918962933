import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #c4bdb5;
  .mo_only {
    display: none;
  }
  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #ffbc00;
  }
  .global-wrapper {
    max-width: 768px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    .cover-wrap {
      position: relative;
      z-index: 1;
      background-color: #ffcb00;
      .cover-box {
        padding: 0 20px;
      }
      .cover-title {
        font-weight: 500;
        font-size: 26px;
        line-height: 32px;
        padding-top: 160px;
        text-align: center;
      }
      .main-img-box {
        width: 320px;
        margin: 60px auto;
        img {
          width: 100%;
        }
      }
      .logo-title-box {
        width: 210px;
        margin: 20px auto;
        img {
          width: 100%;
        }
      }
      .scroll-box {
        width: 24px;
        margin: 0 auto;
        padding: 44px 0 60px 0;
        position: relative;
        bottom: 0;
        animation: down 3s infinite;
        img {
          width: 100%;
        }
        @keyframes down {
          50% {
            bottom: 5px;
          }
        }
      }
    }
    .content-wrap {
      background-color: #fff;
      .first-box {
        position: relative;
        .circle {
          width: 100px;
          position: absolute;
          top: 0;
          right: 0;
          img {
            width: 100%;
          }
          &::after {
            content: "";
            position: absolute;
            top: 87px;
            right: 60px;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #ffbc00;
            opacity: 0.6;
          }
        }
        .logo-title-color {
          width: 233px;
          margin: 0 auto;
          position: absolute;
          z-index: 1;
          top: 38px;
          left: 50%;
          transform: translateX(-50%);
          background-color: #fff;
          img {
            width: 100%;
          }
        }
        .top-triangle {
          display: block;
          padding-top: 136px;
          margin-left: 20px;
        }
        .content-text {
          padding: 30px 20px 20px 20px;
          font-weight: 600;
          font-size: 19px;
          line-height: 24px;
          letter-spacing: -0.03em;
          /* word-break: break-all; */
        }
        .under-triangle {
          display: flex;
          justify-content: end;
          margin-right: 40px;
        }
        .kb-banner-box {
          margin-top: 18px;
          position: relative;
          z-index: 2;
          img {
            width: 100%;
          }
        }
      }
      .second-box {
        position: relative;
        .circle-box {
          border: 1px solid transparent;
          .big-circle {
            width: 253px;
            height: 253px;
            border: 1px dashed #ffbc00;
            border-radius: 50%;
            position: absolute;
            z-index: 1;
            left: -95px;
            top: -99px;
          }
          .color-circle {
            position: absolute;
            top: 40px;
            left: 106px;
            width: 82px;
            height: 82px;
            border-radius: 50%;
            background-color: #f7f7f7;
          }
          .second-triangle {
            position: absolute;
            top: 37px;
            right: 72px;
          }
          .s-circle {
            width: 81px;
            position: absolute;
            right: 0;
            top: -40px;
            img {
              width: 100%;
            }
          }
        }
        .second-text-box {
          margin-top: 167px;
          padding: 0 25px;
          .ceo-title {
            font-weight: 700;
            font-size: 22px;
            line-height: 27px;
            margin-top: 13px;
          }
          .desc {
            margin-top: 39px;
            font-weight: 300;
            font-size: 12px;
            line-height: 16px;
          }
        }
        .ceo-img-box {
          margin-top: 23px;
          display: flex;
          .ceo {
            width: 389px;
            object-fit: cover;
            border-radius: 0 20px 20px 0;
          }
          .ceo-intro {
            position: relative;
            top: 188px;
            .ceo-title {
              font-weight: 500;
              font-size: 22px;
              line-height: 27px;
              margin-left: -27px;
            }
            .signature {
              width: 88px;
              position: absolute;
              right: 0px;
              transform: translateY(4px);
            }
          }
        }
      }
      .history-wrap {
        margin-top: 114px;
        .history-title {
          margin-left: 25px;
          margin-bottom: 20px;
        }
        .history-bg {
          background-color: #f7f7f7;
          padding: 23px 25px 35px 25px;
          position: relative;
          .history-list {
            position: relative;
            z-index: 1;
            li {
              background-color: #fff;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 5px;
              padding: 13px 10px 12px 11px;
              text-align: center;
              font-weight: 700;
              font-size: 12px;
              line-height: 15px;
              text-align: center;
              letter-spacing: -0.02em;
              margin-top: 57px;
              span {
                font-weight: 300;
              }
              &:first-child {
                margin-top: 0;
              }
            }
          }
          .list-line {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            li {
              width: 19px;
              height: 19px;
              border-radius: 50%;
              border: 4px solid #ffbc00;
              background-color: #fff;
              position: relative;
              z-index: 1;
            }
            .line-circle {
              margin-top: 100px;
              &::after {
                position: absolute;
                content: "";
                top: 11px;
                left: 50%;
                transform: translateX(-50%);
                width: 1px;
                height: 1000px;
                border-left: 2px dashed #ffbc00;
              }
            }
            .line-circle2 {
              margin-top: 92px;
            }
            .line-circle3 {
              margin-top: 123px;
            }
            .line-circle4 {
              margin-top: 110px;
            }
            .line-circle5 {
              margin-top: 91px;
            }
            .line-circle6 {
              margin-top: 96px;
            }
          }
        }
      }
      .business-wrap {
        padding: 117px 25px 0px 25px;
        .business-banner {
          margin-top: 23px;
          position: relative;
          img {
            width: 100%;
          }
          .banner-text {
            position: absolute;
            bottom: 34px;
            left: 27px;
            font-weight: 500;
            font-size: 22px;
            line-height: 27px;
            color: #ffffff;
          }
        }
        .business-text-box {
          margin-top: 21px;
          padding: 0 22px;
          .business-desc {
            font-weight: 300;
            font-size: 12px;
            line-height: 15px;
            span {
              font-weight: 700;
              font-size: 13px;
              line-height: 16px;
            }
          }
          .under-desc {
            margin-top: 21px;
            padding-bottom: 21px;
          }
        }
        .work-box {
          margin-top: 84px;
          .vision-tit {
            margin-left: 18px;
          }
          .work-text {
            font-weight: 600;
            font-size: 19px;
            line-height: 24px;
            letter-spacing: -0.02em;
            margin-top: 30px;
          }
        }
      }
      .graph-img-box {
        margin-top: 30px;
        img {
          width: 100%;
        }
      }
      .vision-wrap {
        margin-top: 57px;
        width: 300px;
        margin: 0 auto;
        .vision-text-box {
          margin-top: 20px;
          .vision-text {
            font-style: italic;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
          }
          .top-mark {
            svg {
              position: relative;
              left: 10px;
            }
          }
          .under-mark {
            display: flex;
            justify-content: end;
            -webkit-box-pack: end;
            svg {
              transform: rotate(180deg);
              position: relative;
              right: 0;
              top: -7px;
            }
          }
        }
      }
      .vision-img-box {
        margin-top: 18px;
        position: relative;
        img {
          width: 100%;
        }
        .vision-img-title {
          position: absolute;
          width: 100%;
          top: 43px;
          text-align: center;
          font-weight: 300;
          font-size: 12px;
          line-height: 15px;
          color: #ffffff;
          span {
            color: #ffcb00;
            font-weight: 600;
          }
        }
        .graph-desc-box {
          display: flex;
          justify-content: space-evenly;
          .vision-img-text {
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            letter-spacing: -0.02em;
            position: relative;
            top: -250px;
          }
          .is-text {
            margin-left: 15px;
          }
          .sw-text {
            margin-left: 29px;
            margin-top: -15px;
          }
        }
      }
      .growth-wrap {
        margin-top: 60px;
        padding: 0 25px;
        .growth-title {
          margin-left: 13px;
        }
        .growth-box {
          display: flex;
          margin-top: 44px;
          height: 177px;
          .total-wrap {
            border-right: 1px solid #000;
            width: 73px;
            .total-title {
              font-weight: 700;
              font-size: 13px;
              line-height: 17px;
              text-align: center;
              margin-right: 16px;
              span {
                font-size: 11px;
                line-height: 14px;
                color: #707070;
                margin-top: 7px;
              }
            }
            img {
              width: 40px;
              position: relative;
              top: 77px;
              left: 10px;
            }
            .year-title {
              font-weight: 600;
              font-size: 12px;
              line-height: 15px;
              position: relative;
              top: 93px;
              left: 19px;
            }
          }
          .growth-graph-wrap {
            display: flex;
            align-items: end;
            -webkit-align-items: end;
            -webkit-box-align: end;
            justify-content: space-evenly;
            flex: 1 1 100%;
            /* margin-left: 37px; */
            gap: 52px;
            position: relative;
            top: 21px;
            .growth-graph-box {
              position: relative;
              .growth-title {
                font-weight: 700;
                font-size: 13px;
                line-height: 17px;
                position: absolute;
                left: -30px;
                top: -20px;
              }
              .million1 {
                color: #918b83;
              }
              .million2 {
                color: #8f7042;
              }
              .million3 {
                color: #ffbc00;
              }
              .growth-graph1 {
                width: 22px;
                height: 63px;
                background-color: #918b83;
              }
              .growth-graph2 {
                width: 22px;
                height: 104px;
                background-color: #8f7042;
              }
              .growth-graph3 {
                width: 22px;
                height: 145px;
                background-color: #ffcc00;
              }
              .year-title {
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                margin-left: -2px;
                margin-top: 6px;
              }
            }
          }
        }
        .line-top {
          border-top: 1px solid #000;
        }

        .employees-wrap {
          margin-top: 58px;
          .employees-box {
            border-right: 1px solid #000;
            width: 73px;
            .employees-title {
              font-weight: 700;
              font-size: 13px;
              line-height: 17px;
              text-align: right;
              position: relative;
              left: -8px;
              span {
                font-size: 11px;
                line-height: 14px;
                color: #707070;
              }
            }
            img {
              width: 40px;
              position: relative;
              top: 96px;
              left: 15px;
            }
            .year-title {
              font-weight: 600;
              font-size: 12px;
              line-height: 15px;
              position: relative;
              top: 111px;
              left: 19px;
            }
          }
          .growth-graph-wrap {
            .growth-graph-box {
              .head {
                width: 22px;
                height: 22px;
                border-radius: 50%;
                background-color: #918b83;
                margin-bottom: 4px;
              }
              .head2 {
                background-color: #8f7042;
              }
              .head3 {
                background-color: #ffcc00;
              }
              .body-graph1 {
                width: 22px;
                height: 41px;
                background-color: #918b83;
                border-radius: 10px 10px 0px 0px;
              }
              .body-graph2 {
                width: 22px;
                height: 82px;
                background-color: #8f7042;
                border-radius: 10px 10px 0px 0px;
              }
              .body-graph3 {
                width: 22px;
                height: 114px;
                background-color: #ffcc00;
                border-radius: 10px 10px 0px 0px;
              }
              .growth-title {
                font-weight: 700;
                font-size: 13px;
                line-height: 17px;
                position: absolute;
                left: -15px;
                top: -20px;
              }
            }
          }
        }

        .growth-desc-wrap {
          margin-top: 104px;
          .global-tit {
            margin-left: 18px;
          }
          .growth-desc {
            font-weight: 600;
            font-size: 19px;
            line-height: 24px;
            letter-spacing: -0.02em;
            margin-top: 35px;
          }
        }
      }
    }
    .global-banner-wrap {
      margin-top: 38px;
      img {
        width: 100%;
      }
    }
    .network-wrap {
      margin-top: 45px;
      padding: 0 38px;
      img {
        width: 100%;
        margin-top: 20px;
      }
    }
    .word-wrap {
      position: relative;
      img {
        width: 100%;
      }
      .map-arrow {
        position: relative;
        z-index: 1;
      }
      .word-map-banner {
        position: relative;
        .word-map {
          margin-top: -40px;
        }
        .top-kbds-map-box {
          position: absolute;
          top: 48%;
          left: 63%;
          .kbds1 {
            font-weight: 500;
            font-size: 9px;
            line-height: 11px;
            padding-left: 5px;
            &::after {
              position: absolute;
              content: "";
              top: 12px;
              left: 0;
              width: 101px;
              height: 16px;
              border-top: 1px solid #4b4b4b;
              border-left: 1px solid #4b4b4b;
              transform: skew(-30deg);
            }
          }
        }
        .under-kbds-map-box {
          position: absolute;
          top: 74.5%;
          left: 45%;
          .kbds2 {
            font-weight: 500;
            font-size: 9px;
            line-height: 11px;
            text-indent: -5px;
            &::after {
              position: absolute;
              content: "";
              top: -19px;
              left: 0;
              width: 101px;
              height: 16px;
              border-bottom: 1px solid #4b4b4b;
              border-right: 1px solid #4b4b4b;
              transform: skew(-30deg);
            }
          }
        }
      }
    }
    .table-wrap {
      padding: 15px 0;
      background-color: #ffe472;
      table {
        width: 310px;
        margin: 0 auto;
        text-align: center;
        thead {
          background-color: #ffcb00;
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
          td {
            padding: 4px 0;
            &:first-child {
              border-right: 3px solid #fff;
            }
          }
        }
        tbody {
          background-color: #fff;
          font-weight: 500;
          font-size: 9px;
          line-height: 11px;
          tr {
            border-bottom: 0.25px solid #ffbc00;
            &:last-child {
              border-bottom: 1px solid #ffbc00;
            }
          }
          td {
            padding: 7px 0;
            &:first-child {
              border-right: 3px solid #fff;
            }
          }
        }
      }
    }
    .contact-wrap {
      padding: 49px 24px 82px 38px;
      .contact-text-box {
        margin-top: 34px;
        .contact-text {
          font-weight: 300;
          font-size: 12px;
          line-height: 15px;
          span {
            font-weight: 700;
            font-size: 13px;
            line-height: 16px;
          }
        }
      }
      .contact-second {
        margin-top: 39px;
      }
    }
    .contact-icon-box {
      display: flex;
      justify-content: end;
      margin-top: -25px;
      position: relative;
      .icon-bg {
        width: 41px;
        height: 42px;
        border-radius: 50%;
        background-color: #ffe472;
        position: absolute;
        right: 9px;
        top: -4px;
      }
      img {
        width: 41px;
        position: relative;
      }
    }
  }
  .footer-wrap {
    background-color: #585858;
    .logo-box {
      width: 156px;
      margin: 0 auto;
      padding: 14px 0;
    }
    img {
      width: 100%;
    }
  }

  @media ${(props) => props.theme.mobile} {
    .mo_only {
      display: block;
    }
    .global-wrapper {
      .cover-wrap {
        .cover-box {
        }
        .cover-title {
        }
        .main-img-box {
        }
        .logo-title-box {
        }
        .scroll-box {
        }
      }
      .content-wrap {
        .first-box {
          .circle {
          }
          .logo-title-color {
          }
          .top-triangle {
          }
          .content-text {
            /* word-break: break-word; */
          }
          .under-triangle {
          }
          .kb-banner-box {
          }
        }
        .second-box {
          .circle-box {
            .big-circle {
            }
            .color-circle {
            }
            .second-triangle {
            }
            .s-circle {
            }
          }
          .second-text-box {
            .ceo-title {
            }
            .desc {
            }
          }
          .ceo-img-box {
            .ceo {
              width: 239px;
            }
            .ceo-intro {
              top: 118px;
              .ceo-title {
                font-size: 12px;
                line-height: 15px;
              }
              .signature {
                width: 68px;
              }
            }
          }
        }
        .history-wrap {
          .history-title {
          }
          .history-bg {
            .history-list {
              li {
              }
            }
            .list-line {
              li {
              }
              .line-circle {
              }
              .line-circle2 {
                margin-top: 92px;
              }
              .line-circle3 {
                margin-top: 123px;
              }
              .line-circle4 {
                margin-top: 110px;
              }
              .line-circle5 {
                margin-top: 106px;
              }
              .line-circle6 {
                margin-top: 96px;
              }
            }
          }
        }
        .business-wrap {
          .business-banner {
            .banner-text {
            }
          }
          .business-text-box {
            .business-desc {
              span {
              }
            }
            .under-desc {
            }
          }
          .work-box {
            .vision-tit {
            }
            .work-text {
            }
          }
        }
        .graph-img-box {
        }
        .vision-wrap {
          .vision-text-box {
            .vision-text {
            }
            .top-mark {
              svg {
              }
            }
            .under-mark {
              svg {
              }
            }
          }
        }
        .vision-img-box {
          .vision-img-title {
          }
          .graph-desc-box {
            .vision-img-text {
              top: -130px;
              left: -5px;
            }
            .is-text {
              margin-left: 0;
              position: relative;
              left: -18px;
            }
            .sw-text {
              margin-left: 0;
              position: relative;
              left: -14px;
            }
          }
        }
        .growth-wrap {
          padding: 0 24px;
          .growth-title {
          }
          .growth-box {
            .total-wrap {
              margin-left: 16px;
              .total-title {
              }
              img {
                position: relative;
                left: inherit;
              }
              .year-title {
                left: 10px;
              }
            }
            .growth-graph-wrap {
              margin-left: 37px;

              .growth-graph-box {
                .growth-title {
                }

                .year-title {
                }
              }
            }
          }
          .line-top {
          }

          .employees-wrap {
            .employees-box {
              .employees-title {
              }

              .year-title {
              }
            }
            .growth-graph-wrap {
              .growth-graph-box {
                .head {
                }

                .growth-title {
                }
              }
            }
          }

          .growth-desc-wrap {
            .global-tit {
            }
            .growth-desc {
            }
          }
        }
      }
      .global-banner-wrap {
      }
      .network-wrap {
      }
      .word-wrap {
        .map-arrow {
        }
        .word-map-banner {
          .word-map {
            margin-top: -20px;
          }
          .top-kbds-map-box {
            position: absolute;
            top: 43%;
            left: 64%;
            .kbds1 {
              font-weight: 500;
              font-size: 9px;
              line-height: 11px;
              padding-left: 5px;
              &::after {
                position: absolute;
                content: "";
                top: 12px;
                left: 0;
                width: 101px;
                height: 16px;
                border-top: 1px solid #4b4b4b;
                border-left: 1px solid #4b4b4b;
                transform: skew(-30deg);
              }
            }
          }
          .under-kbds-map-box {
            position: absolute;
            top: 77.5%;
            left: 29%;
            .kbds2 {
              font-weight: 500;
              font-size: 9px;
              line-height: 11px;
              text-indent: -5px;
              &::after {
                position: absolute;
                content: "";
                top: -19px;
                left: 0;
                width: 101px;
                height: 16px;
                border-bottom: 1px solid #4b4b4b;
                border-right: 1px solid #4b4b4b;
                transform: skew(-30deg);
              }
            }
          }
        }
      }
      .table-wrap {
        table {
          thead {
          }
          tbody {
          }
        }
      }
      .contact-wrap {
        .contact-text-box {
          .contact-text {
          }
        }
        .contact-second {
        }
      }
      .contact-icon-box {
      }
    }
  }
`;
