import React, { useEffect, useRef, useState } from "react";
import {
  GetPeopleCultures,
  GetPeopleLives,
  GetPeopleMains,
} from "../../api/people";
import Button from "../../components/Button";
import { MediaQuery, mobileQuery } from "../../components/Grid";
import LoadInView from "../../components/LoadInView";
import Popup from "../../components/Popup";
import YoutubeSlider from "../People/components/youtubeSlick";
import { KBDSYoutube, YoutubeSliderWrap } from "../People/styled";

const YOUTUBE_CATEGORY = [
  { content: "PR", key: "promotion" },
  { content: "Recruit", key: "employment" },
  { content: "Culture", key: "group" },
];
export default function SectionYoutube() {
  const isMobile = MediaQuery(mobileQuery);

  const [show, setShow] = useState(false);

  const [pTitle, setpTitle] = useState("");
  const [liveItem, setLiveItem] = useState("");
  const showPopup = (args) => {
    // setpTitle(args);
    setLiveItem(args);
    setShow((p) => !p);
  };

  const [videoShow, setVideoShow] = useState(false);
  const showVideo = (args) => {
    setVideoShow((p) => !p);
    setYoutubeId(args);
  };
  const [yotubeId, setYoutubeId] = useState("");
  const [post_list, update_post_list] = useState(0);

  const [live_list, update_live_list] = useState("");

  const [youtubeCategory, setYoutubeCategory] = useState(
    YOUTUBE_CATEGORY[0].key
  );
  const [mainInfo, setMainInfo] = useState({});
  const [cultureInfo, setCultureInfo] = useState({});

  const [on, setOn] = useState(false);
  const playVideo = () => {
    setOn(true);
    setTimeout(() => {
      player.current && player.current.internalPlayer.playVideo();
    }, [100]);
  };

  const player = useRef(null);

  const opts = {
    height: "418px",
    width: "100%",
    playerVars: {
      autoplay: 0,
      mute: 0,
      controls: 1,
    },
  };

  const getCultures = () => {
    GetPeopleCultures()
      .then((response) => {
        if (response.data.body) {
          setCultureInfo(response.data.body);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getMainData = () => {
    GetPeopleMains()
      .then((response) => {
        if (response.data.body) {
          setMainInfo(response.data.body);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getLiveData = () => {
    GetPeopleLives({
      postsPerPage: 4,
      paged: 1,
    })
      .then((response) => {
        if (response.data.body.posts) {
          update_live_list(response.data.body.posts);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getLiveData();
    getMainData();
    getCultures();
  }, []);

  const movePage = (link) => window.open(`${link}`, "_blank");
  return (
    <>
      <LoadInView id="youtube">
        <KBDSYoutube>
          <div className="container">
            <div className="flex">
              <div>
                <h4>KBDS Youtube</h4>
                <div className="desc">KB데이타시스템 공식 유튜브</div>
              </div>
              {!isMobile && (
                <Button
                  type="primary"
                  color="white_"
                  onClick={() => movePage("https://www.youtube.com/@kbds")}
                  // onClick={() => showPopup('KBDS Youtube')}
                >
                  KBDS Youtube
                </Button>
              )}
            </div>
            <p>
              {YOUTUBE_CATEGORY.map((item) => (
                <span
                  key={item.key}
                  className={`youtube-category${
                    youtubeCategory === item.key ? "-active" : ""
                  }`}
                  onClick={() => setYoutubeCategory(item.key)}
                >
                  {item.content}
                </span>
              ))}
            </p>
            <YoutubeSliderWrap>
              <YoutubeSlider
                onShow={showVideo}
                youtubeCategory={youtubeCategory}
                style={{
                  marginBottom: "calc(65px + 120px)",
                  barColor: "#FFBC00",
                }}
              />
            </YoutubeSliderWrap>
            {isMobile && (
              <Button
                type="primary"
                onClick={() => movePage("https://www.youtube.com/@kbds")}
              >
                자세히보기
              </Button>
            )}
          </div>
        </KBDSYoutube>
      </LoadInView>
      <Popup
        type="video"
        youtube_id={yotubeId}
        show={videoShow}
        onClose={() => setVideoShow((p) => !p)}
      />
    </>
  );
}
